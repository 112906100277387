import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import PageHeader from "../PageHeader";
import ProjectDescription from "./ProjectDescritpion";
import ProjectName from "./ProjectName";
import ReactPlayer from "react-player";
import useMediaQuery from "../../hooks/useMediaQuery";
import "../../styles/Project.css";

const Projects = ({
  projectName,
  kindOfProject,
  seeMoreText,
  isFirst,
  videoSrc,
}) => {
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [width] = useMediaQuery();
  const isMobile = width <= 1200;
  const handleSeeMore = () => {
    setShowSeeMore(!showSeeMore);
  };

  const container = {
    marginTop: "5vh",
    display: "flex",
    flexDirection: "column",
    marginBottom: "2vh",
    width: "100%",
  };

  const rotateArrow = {
    transform: "rotate(90deg)",
  };

  const seeMoreStyle = {
    display: "flex",
    cursor: "pointer",
    color: "white",
    fontSize: isMobile ? "1vh" : "2vh",
    justifyContent: "flex-end",
    marginTop: "10px",
  };

  const arrowDownUp = useSpring({
    width: "1vw",
    height: "1vh",
    marginRight: "1px",
    marginTop: showSeeMore ? (isMobile ? "5px" : "10px") : "0px",
    transform: showSeeMore ? "rotate(180deg)" : "rotate(0deg)",
  });

  return (
    <>
        <PageHeader />
      <div style={container}>
        <ProjectName
          projectName={projectName}
          kindOfProject={kindOfProject}
          showSeeMore={showSeeMore}
          videoSrc={videoSrc}
        />
        <div className="player-wrapper">
          <ReactPlayer
            width={"100%"}
            height="100%"
            controls
            muted
            url={videoSrc}
          />
        </div>
        {seeMoreText && (
          <>
            <div style={seeMoreStyle} onClick={handleSeeMore}>
              <animated.div style={arrowDownUp}>
                <img
                  style={rotateArrow}
                  src="/img/GoBackBlack.png"
                  alt="get down/Up"
                />
              </animated.div>
              See more
            </div>
          </>
        )}
      </div>
      <ProjectDescription
        isMobile={isMobile}
        isShow={showSeeMore}
        text={seeMoreText}
      />
    </>
  );
};

export default Projects;
