import React from "react";

const ProjectDescription = ({ text, isShow, isMobile }) => {
    const textSeeMoreStyle = {
      display: 'flex',
      marginTop: isMobile ? '2vh' : '1vh',
      marginLeft: '2vw',
      marginRight: '2vw',
      color: 'white',
      fontSize: '2vh',
      overflow: 'hidden',
      maxWidth: isMobile ? '50vw' : '',
    };
  
    const normalFont = {
      fontFamily: 'Roboto',
    };
  
    return (
      <>
        {isShow && (
          <div style={{ ...textSeeMoreStyle, ...normalFont }}>
            {text}
          </div>
        )}
      </>
    );
  };
  
  export default ProjectDescription;
  