import React, { useRef, useEffect } from "react";
import "./LoadingPage.css";

function LoadingPage() {
  const videoRef = useRef(null); // Créez une référence pour l'élément vidéo

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 30;
      videoRef.current.play();
    }
  }, []);

  return (
    <div className="center-content">
      <img src="/img/ghost.gif" alt="Ghost Film Logo" />
      <h1>GHOST FILM</h1>
      <video
        src="/videos/GhostTeaser.mp4"
        ref={videoRef}
        className="video-slide"
        autoPlay
        loop
        muted
      />
    </div>
  );
}

export default LoadingPage;
