import React, { useState } from "react";
import MenuSelections from "../components/MenuSelections";
import Menu from "../components/Menu";
import useMediaQuery from "../hooks/useMediaQuery";

const Abouts = () => {
  const [width] = useMediaQuery();
  const isMobile = width <= 800;
  const container = {
    display: "flex",
    flexDirection: "column",
  };

  const videoStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  };

  const soundStyle = {
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    right: "2vw",
    top: isMobile ? "22vw" : "5vw",
    width: "15vw",
    height: "auto",
  };

  const [isVideoMute, setVideoMute] = useState(true);
  const [isShowingMenu, setMenu] = useState(false);
  const [isCross, setIsCross] = useState(false);

  const handleMenuSelectionsClick = () => {
    setIsCross(!isCross);
    setMenu(!isShowingMenu);
  };

  const handleMutingVideo = (value) => {
    setVideoMute(value);
  };

  return (
    <>
      <MenuSelections isCross={isCross} onClick={handleMenuSelectionsClick} />
      {isShowingMenu ? (
        <Menu />
      ) : (
        <div style={container}>
          <video
            playsInline="playsInline"
            style={videoStyles}
            autoPlay
            loop
            muted={isVideoMute}
          >
            <source src="/videos/GhostTeaser.mp4" type="video/mp4" />
          </video>
          <div style={soundStyle}>
            <img
              src={isVideoMute ? "/img/muteVideo.png" : "/img/unMuteVideo.png"}
              alt="videoSoundState"
              width={40}
              onClick={() => handleMutingVideo(!isVideoMute)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Abouts;
