import React from "react";
import { useSpring, animated } from "react-spring";
import "../styles/HomePage.css";
const MenuSelections = ({ isCross, onClick }) => {
  const menuSelectionsAnimation = useSpring({
    transform: isCross ? "rotate(180deg)" : "rotate(0deg)",
  });

  return (
    <animated.div
      className="menuSelections"
      style={menuSelectionsAnimation}
      onClick={onClick}
    >
      <img
        src={isCross ? "/img/CrossClose.png" : "/img/MenuSelections.png"}
        alt={isCross ? "CrossClose" : "MenuSelections"}
      />
    </animated.div>
  );
};

export default MenuSelections;
