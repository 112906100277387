import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import TalentComponent from "../components/TalentComponent";
import MenuSelections from "../components/MenuSelections";
import Menu from "../components/Menu";

const Talents = () => {
  const [isShowingMenu, setMenu] = useState(false);
  const [isCross, setIsCross] = useState(false);

  const handleMenuSelectionsClick = () => {
    setIsCross(!isCross);
    setMenu(!isShowingMenu);
  };
  return (
    <div style={{ overflow: 'hidden' }}>
      <MenuSelections isCross={isCross} onClick={handleMenuSelectionsClick} />
      {isShowingMenu ? (
        <Menu />
      ) : (
        <>
          <PageHeader />
          <div>
            <TalentComponent />
          </div>
        </>
      )}
    </div>
  );
};
export default Talents;
