import React, { useLayoutEffect, useRef } from "react";
import { PowerGlitch } from "powerglitch";

const GlitchWord = ({ word, autoplay = false }) => {
  const wordRef = useRef();
  useLayoutEffect(() => {
    PowerGlitch.glitch(wordRef.current, {
      playMode: "always",
      createContainers: true,
      hideOverflow: false,
      timing: autoplay
        ? {}
        : {
            duration: 500,
            iterations: 1,
          },
      glitchTimeSpan: autoplay
        ? {}
        : {
            start: 0,
            end: 1,
          },
      shake: {
        velocity: 15,
        amplitudeX: 0.2,
        amplitudeY: 0.2,
      },
      slice: {
        count: 6,
        velocity: 15,
        minHeight: 0.02,
        maxHeight: 0.15,
        hueRotate: true,
      },
      pulse: false,
    });
  }, [autoplay]);

  return (
    <div>
      <div ref={wordRef} className=" glitched-word" data-text={word}>
        {word}
      </div>
    </div>
  );
};

export default GlitchWord;
