import React, { useState, useRef } from "react";
//styles
import "../index.css";
import "../styles/HomePage.css";
//components
import Menu from "../components/Menu";
import MenuSelections from "../components/MenuSelections";
import WordCarousel from "../components/WordCarousel";
//hooks
import useMediaQuery from "../hooks/useMediaQuery";

function HomePage() {
  const words = [
    "OFF-WHITE",
    "VUITTON",
    "WESTFIELD",
    "NIKE",
    "Smino",
    "Romeo Elvis",
    "Tems",
  ];
  const [index, setIndex] = useState(0);
  const [width] = useMediaQuery();
  const timeoutRef = useRef(null);
  const isMobile = width <= 800;
  const [startY, setStartY] = useState(0);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    const endY = e.changedTouches[0].clientY;

    if (startY < endY) {
      handleScroll({ deltaY: -1 });
    } else if (startY > endY) {
      handleScroll({ deltaY: 1 });
    }
  };
  document.body.style.overflow = 'hidden';
  const handleScroll = (e) => {
    if (timeoutRef.current) return;

    if (e.deltaY > 0 && index < words.length - 1) {
      setIndex((prev) => prev + 1);
    } else if (e.deltaY < 0 && index > 0) {
      setIndex((prev) => prev - 1);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
    }, 900);
  };

  const [isCross, setIsCross] = useState(false);
  const [isShowingMenu, setMenu] = useState(false);

  const handleMenuSelectionsClick = () => {
    setIsCross(!isCross);
    setMenu(!isShowingMenu);
  };

  return (
    <div
      onWheel={handleScroll}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <MenuSelections isCross={isCross} onClick={handleMenuSelectionsClick} />
      {isShowingMenu ? (
        <Menu />
      ) : (
        <div className={isMobile ? "mobile-container" : "container"}>
          <img src="/img/ghost.gif" alt="Logo" className="logo" />
          <div className="text-container">
            <div className="centerWords">GHOST </div>
            <WordCarousel words={words} index={index} isMobile={isMobile} />
          </div>
        </div>
      )}
      <div className="ghost-mode">* choose your ghost mode</div>
    </div>
  );
}

export default HomePage;
