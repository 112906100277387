import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import HomePage from "./pages/Home";
import Talents from "./pages/Talents";
import Abouts from "./pages/Abouts";
import Contacts from "./pages/Contact";
import Shop from "./pages/Shop";
import ProjectPage from "./pages/Project";

const projectTabText = [
  {
    title: "NIKE",
    category: "Brand",
    description: "GODDESS AWAKENED",
    seeMoreText: "",
    video: "/videos/nike.mp4",
  },
  {
    title: "OFF-WHITE",
    category: "Brand",
    description: 'Spaceship Earth "Imaginary Experiance"',
    seeMoreText: "",
    video: "/videos/off_white_web.mp4",
  },
  {
    title: "The Elder Statesman",
    category: "Brand",
    description: "",
    seeMoreText: "",
    video: "/videos/elder_statesman.mp4",
  },
  {
    title: "Smino",
    category: "Music",
    description: "Ole Ass Kendrick",
    seeMoreText: "",
    video: "/videos/smino.mp4",
  },
  {
    title: "Romeo Elvis",
    category: "Music",
    description: "13/12",
    seeMoreText: "",
    video: "/videos/romeo_elvis.mp4",
  },
  {
    title: "Tems",
    category: "Music",
    description: "ME & You",
    seeMoreText: "",
    video: "/videos/tems.mp4",
  },
];

function App() {
  // Vous pouvez utiliser un état pour gérer la fin de l'animation de chargement.
  const [loadingFinished, setLoadingFinished] = useState(false);
  // Simuler la fin de chargement après 5 secondes.
  useEffect(() => {
    setTimeout(() => {
      setLoadingFinished(true);
    }, 3000);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={loadingFinished ? <HomePage /> : <LoadingPage />}
          />
          <Route path="/abouts" element={<Abouts />} />
          <Route
            path="/projects"
            element={<ProjectPage projectData={projectTabText} />}
          />
          <Route path="/talents" element={<Talents />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/shop" element={<Shop />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
