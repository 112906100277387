import React, { useState, useEffect, useRef } from "react";
import "../styles/TalentComponent.css";

const TalentComponent = () => {
  const [elements, setElements] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(1);
  const scrollContainerRef = useRef(null);
  const elementWidth = 300; // Largeur de chaque élément, ajustez selon vos besoins

  useEffect(() => {
    setElements([
      { name: "TRISTAN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
      { name: "ADIL VOISIN", role: "RÉALISATEUR" },
    ]);
  }, []);

  const handleElementClick = (index) => {
    setHighlightedIndex(index);
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const scrollLeft =
        elementWidth * highlightedIndex - containerWidth / 2 + elementWidth / 2;
      scrollContainerRef.current.scrollLeft = scrollLeft;
    }
  }, [highlightedIndex, elementWidth]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <div className="talent-scroll-container" ref={scrollContainerRef}>
        {elements.map((element, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: `${elementWidth}px`,
              minHeight: "220px",
              margin: "0 10px",
              transition: "all 0.5s",
              transform: index === highlightedIndex ? "scale(1.2)" : "scale(1)", // Ajoute un effet de mise à l'échelle pour l'élément mis en évidence
            }}
            onClick={() => handleElementClick(index)}
          >
            <div
              className={`element ${
                index === highlightedIndex ? "highlighted" : ""
              }`}
            >
              {element.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TalentComponent;
