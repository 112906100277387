import React, { useState } from "react";
import { Input } from "@mui/material";
import MenuSelections from "../components/MenuSelections";
import Menu from "../components/Menu";
import PageHeader from "../components/PageHeader";
import "./Contact.css";
import useMediaQuery from "../hooks/useMediaQuery";
import "../styles/HomePage.css";

const Contacts = () => {
  const [isShowingMenu, setMenu] = useState(false);
  const [isCross, setIsCross] = useState(false);
  const [width] = useMediaQuery();
  const isMobile = width <= 800;
  const container = {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    left: "25vw",
    top: isMobile ? "30%" : "15vw",
  };

  const textStyle = {
    marginTop: "20px",
    color: "white",
  };

  const inputStyle = {
    marginTop: isMobile ? "3vw" : "2vw",
    color: "white",
    flex: 2,
  };

  const handleMenuSelectionsClick = () => {
    setIsCross(!isCross);
    setMenu(!isShowingMenu);
  };

  return (
    <div>
      <MenuSelections isCross={isCross} onClick={handleMenuSelectionsClick} />
      {isShowingMenu ? (
        <Menu />
      ) : (
        <>
          <PageHeader />
          <div style={container}>
            <div style={textStyle}>
              <b>LET'S KEEP IN TOUCH</b>
            </div>
            <Input
              style={inputStyle}
              size="small"
              placeholder="Name"
              variant="filled"
            />
            <Input
              style={inputStyle}
              size="small"
              placeholder="Mail Address"
              variant="outlined"
            />
            <Input
              style={inputStyle}
              size="small"
              placeholder="Subject"
              variant="outlined"
            />
            <Input
              style={inputStyle}
              size="small"
              placeholder="Message"
              variant="outlined"
            />
          </div>
          <img
            src="/img/ghost.gif"
            alt="Ghost Film Logo"
            class="centred-bottom-gif"
          />
        </>
      )}
    </div>
  );
};

export default Contacts;
