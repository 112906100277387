import React from "react";
import GlitchWord from "../GlitchWord";
import useMediaQuery from "../../hooks/useMediaQuery";

const ProjectName = ({ projectName, kindOfProject }) => {
  const [width] = useMediaQuery();
  const isMobile = width <= 800;

  const projectDescriptionStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: isMobile ? '10vw' : '',
    width: isMobile ? '85vw' : "70vw",
  };

  const projectNameStyle = {
    color: "white",
    fontSize: isMobile ? "15px" : "40px",
  };

  const kindOfProjectStyle = {
    color: "white",
    fontSize: "25px",
    paddingTop: '18px',
  };

  const kindOfProjectMobileStyle = {
    color: "white",
    fontSize: "10px",
    marginTop: "10px",
  };

  return (
    <div style={{ ...projectDescriptionStyle }}>
      <i>
        <div style={projectNameStyle}>
          <GlitchWord word={projectName} />
        </div>
      </i>
      <div
        style={isMobile ? kindOfProjectMobileStyle : kindOfProjectStyle}
      >{`${kindOfProject}`}</div>
    </div>
  );
};

export default ProjectName;
