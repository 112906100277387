import React, { useState } from "react";
import Projects from "../components/Projects/Projects";
import MenuSelections from "../components/MenuSelections";
import Menu from "../components/Menu";
import "./Project.css";

const ProjectPage = ({ projectData }) => {
  const [isShowingMenu, setMenu] = useState(false);
  const [isCross, setIsCross] = useState(false);
  const handleMenuSelectionsClick = () => {
    setIsCross(!isCross);
    setMenu(!isShowingMenu);
  };
  document.body.style.overflow = 'visible';
  return (
    <div className="project-page">
      <MenuSelections isCross={isCross} onClick={handleMenuSelectionsClick} />
      {isShowingMenu ? (
        <Menu />
      ) : (
        <div className="project-list">
          {projectData.map((project, index) => (
            <Projects
              key={index}
              projectName={project.title}
              kindOfProject={project.description}
              seeMoreText={project.seeMoreText}
              videoSrc={project.video}
              isFirst={index === 0}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
