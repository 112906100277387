import React from "react";
import { Link } from "react-router-dom";
import "../styles/Menu.css";
import GlitchWord from "./GlitchWord";

const Menu = () => {
  const categories = ["HOME", "ABOUT", "PROJECTS", "CONTACT"];
  const paths = ["/", "/abouts", "/projects", "/contact"];
  return (
    <div className="menu-container">
      {categories.map((category, index) => (
        <Link
          key={index}
          to={paths[index]}
          className={
            window.location.pathname === paths[index]
              ? "activeCategory"
              : "category"
          }
        >
          {window.location.pathname === paths[index] ? (
            <GlitchWord key={index} word={category} />
          ) : (
            category
          )}
        </Link>
      ))}
    </div>
  );
};

export default Menu;
