import React from "react";
import useMediaQuery from "../hooks/useMediaQuery";

const PageHeader = () => {
  const [width] = useMediaQuery();
  const isMobile = width <= 800;
  const logoStyle = {
    height: "auto",
    width: "calc(3.4vw - 5px)",
    maxWidth: "110px",
    minWidth: "17px",
  };

  const titleStyle = {
    position: "absolute",
    color: "white",
    top: isMobile ? "11vh" : '8vh',
    left: "40vw",
    fontSize: "3vw",
    textAlign: "center",
  };

  return (
    <>
      <div style={titleStyle}>
        GH
        <img style={logoStyle} src="/img/ghost_image.png" alt="O" />
        ST FILMS
      </div>
    </>
  );
};

export default PageHeader;
