import React from "react";
import { useSpring, animated } from "react-spring";
import GlitchWord from "./GlitchWord";

const WordCarousel = ({ words, index, isMobile }) => {
  const scrollMultipicator = isMobile ? 1 : 2;
  const springProps = useSpring({
    transform: `translate3d(0, ${
      -index * 43.5 * scrollMultipicator + 130 * scrollMultipicator
    }px, 0)`,
  });

  return (
    <div className="wordContainer">
      <animated.div style={springProps}>
        {words.map((word, i) => {
          return i === index ? (
            <div key={i} className="activeWord">
              <GlitchWord key={i} word={word} />
            </div>
          ) : (
            <div key={i} className={"word"}>
              {word}
            </div>
          );
        })}
      </animated.div>
    </div>
  );
};

export default WordCarousel;
